.input-group {
    margin: 30px;
}

.input-group>label>input {
    margin-left: 20px;
    padding: 10px;
    width: 35vw;
}

#formDiv {
    margin: auto;
    margin-top: 5em;
    font-family: 'Raleway', sans-serif;
}

#displayTable {
    font-family: 'Raleway', sans-serif;

    text-transform: capitalize;
}

#detailForm {

    padding: 30px;
    height: auto;
    border-bottom: 2px solid #1AA99F;
}

#formButtonDiv {
    display: flex;
    justify-content: space-evenly;
}

#formButtonDiv>button {
    width: 10vw
}