#navId {
    top: 0;
    position: fixed;

    width: 100%;
    background-color: #041f31;

    transition: background-color .7s,
}



.navbar.colorChange {
    background-color: #041f31;

}

#navDiv {
    margin: auto;
    width: 100%;

}

.navbar-nav {
    margin-left: 100px;
}

#basic-nav-dropdown {
    color: #14867f;
    font-family: 'Playfair Display', serif;
    line-height: 2;
    margin: 15px;

}

.navDrop {

    padding: 10px
}

.navDrop:hover {
    color: #1AA99F;
    background-color: #041f31;

}

#navUl {
    list-style: none;
    display: flex;
    margin: 1em;
}

@media screen and (min-width:500px) {
    #basic-navbar-nav {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .navbar-nav {
        margin: auto;
    }

    #responsive-navbar-nav {
        /* background-color: #041f31; */
    }

}


#navLink {
    margin: 15px;
    text-decoration: none;
    font-family: 'Playfair Display', serif;
    font-size: 17px;
    color: white;
    display: block;
    letter-spacing: 1px;
    font-size: 20px;

}

#navList {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
}

.navLinks {

    margin: 15px;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    color: white;

}

.nav-item Link {
    font-family: 'Raleway', sans-serif;
}