#loginDiv {
    margin: auto;
    margin-top: 15vh;
    font-family: 'Raleway', sans-serif;
}

#loginForm {
    width: 50%;
    padding: 30px;
    margin: auto;
    height: auto;
    border-bottom: 2px solid #1AA99F;
}

#loginButtonDiv {

    display: flex;
    justify-content: space-evenly;
}