#allArticlesDiv {
    margin-top: 12vw;
    margin-bottom: 5vw;
}

#allArticleImage {
    height: 30vh
}

#allArticlesCard {
    float: left;
    margin: 20px;
    text-decoration: none;
    color: inherit
}

#allArticlesCard:hover {
    box-shadow: 10px 15px 1px #1AA99F,
        0px 1px 4px #1AA99F;
    cursor: pointer;
}

#allArticlesCard {
    border: 1px solid #1AA99F
}

#allConnectionsRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#allArticlesTitle,
#allArticlesSub,
#allArticlesText {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    text-transform: capitalize;

}