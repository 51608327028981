#whatDiv {
    margin-top: 5em;
}

#whatContentDiv,
#whatPracticeDiv {

    background-color: white;
    padding: 20px;

}




#skillImg {
    margin: auto;

    height: 60px;
    text-align: center;
}

#innerQuote {
    padding: 15px;

}

@media only screen and (max-width:600px) {
    #whatPracticeDiv {}

    #whatContentDiv {
        width: auto;
        letter-spacing: 0.5px;
    }

    #innerQuote {
        background-color: transparent;
    }

    #serviceTitle {
        text-align: center;
    }
}



#quoteTextDIv {
    position: absolute;

    /* Adjust as needed to position the overlay */
    left: 50%;
    /* Adjust as needed to position the overlay */
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.082);
    /* Background color with transparency */
    padding: 20px;
    text-align: center;
    color: black;
    /* Text color */
}

#whatSkillRow {
    margin: auto;

    justify-content: space-evenly;
}

#serviceTitle {
    text-transform: capitalize;
    text-align: center;
}

#whatLi {
    text-align: left;
    letter-spacing: 2px;
    word-spacing: 5px;


}

#serviceP {

    font-family: 'Raleway', sans-serif;
}

#skillCard {
    border: none;
}