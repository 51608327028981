#whoAllDiv {
    margin-top: 5em;

}

.whoRow {
    padding: 30px;
    margin: 20px;
    text-align: left;

}

#whoAllContentDiv {
    background-color: white;
    padding: 20px;

}

#northStarLi {
    margin: 15px
}


#controlSvg {
    color: #1AA99F;
}

#mottoDiv {
    float: left;
    margin: auto;
    padding: 20px;
}

.northStarP {
    margin-left: 40px;

    color: #1AA99F;
}

.vissionDiv {
    float: right;
    margin: auto;
    padding: 2em;
}

.quote {

    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    word-spacing: 10px;
    font-size: 39px;
    font-family: 'Raleway', sans-serif;

}

.quoteEng {
    color: white;
    letter-spacing: 2px;
    word-spacing: 10px;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
}

.quoteLang {
    letter-spacing: 2px;
    word-spacing: 5px;
    font-style: italic;
    font-size: 15px;

    font-family: 'Raleway', sans-serif;
}

#whoTitle {
    /* text-align: left;
    margin-left: 7%; */
    margin-top: 20px;
    font-size: 25px;
    font-family: 'Playfair Display', serif;

}

#teamRow {
    padding-bottom: 30p;
}



#whoTeamCard {
    margin: auto;
    padding: 20px;
    transition: transform 1s, filter .3s ease-out;
    border: none;

}

#whoTeamCard:hover {
    transform: scale(1.1);
}

#teamPhoto {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

#teamPhoto:hover {
    cursor: pointer;
}

.teamLink {



    padding-top: 2em;
}

#quoteRow {

    background: rgba(4, 31, 49, 0);

    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% auto;
    height: auto;
    padding: 15px;
}

#innerQuoteRow {
    background-color: #041f31;
    padding: 15px
}

.quote,
.quoteLang {

    color: white
}

.teamLink {
    text-decoration: none;
    color: black;
    justify-content: center;

}

.dotsClass {
    margin: 10px;

}

.rotate-180 {
    transform: rotate(180deg);
}

.teamLink:hover {
    color: black
}



.teamLink h5 {
    color: #1AA99F
}

@media screen and (max-width: 450px) {
    #whoAllContentDiv {
        width: auto;
        letter-spacing: 0.5px;
    }

    .whoRow {
        padding: 0px
    }

    .northStarP {
        margin: 0px;

    }

    #northStarLi {
        margin-left: 0px
    }

    .quote {
        font-size: 30px;
    }

    .quoteEng {}
}