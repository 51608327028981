#adminDiv>ol>li>a {

    text-decoration: none;
    text-transform: capitalize;
}

#adminDiv>ol>li {
    margin: 10px;
    padding-right: 37vw;

}

#adminDiv>ol {
    padding-left: 40vw
}