#practiceAllDiv {
    padding-top: 15px;
}


#practiceContentDiv {
    padding: 20px;
}

#projectCards {
    border: none;


    padding: 28px;
    transform-origin: center;
    transition: transform 1s, filter .3s ease-out;
}

#contactCardPractice {
    border: none
}

#contactCardPractice:hover {
    cursor: pointer;
}

#projectCards:hover {
    transform: scale(1.1);
}

#plxLink {
    text-decoration: none;
    color: inherit;

}

#buttonDiv {
    margin: 10px auto;
}

#dropItem:hover {
    color: #1AA99F;
    background-color: #144466;
}

#dropItem {
    padding: 15px;

}

#practiceBtn {
    margin: 15px;
    font-family: 'Playfair Display',
        serif;
    font-weight: 800;
    letter-spacing: 2px;
    border: none;
    background-color: inherit;
    height: 3em;
    width: 20vw;
    border: 1px solid #144466;
    color: #1AA99F;
    border-radius: 20px;
    text-transform: uppercase;

}



#practiceBtn:focus {
    background-color: #144466;
    /* Adjust focus styles if needed */
    outline: 2px solid #17a2b8;
    /* Set the desired color for the focus outline */
    box-shadow: none;
    /* Remove any default box-shadow on focus */
}

#practiceBtn:hover {
    box-shadow: 0 1px 4px #1AA99F,
        0 1px 4px #1AA99F;
}

#projectBody {
    word-wrap: break-word;
    font-family: 'Raleway', sans-serif;
    white-space: pre-line;

}

#projectTitle {
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
}


#fieldDropDown {

    background-color: inherit;
    color: inherit;
    border: none;

}

#practiceAreaBtnGrp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}



#practiceCol {
    margin-top: 10px;
    background-color: inherit;
    overflow: auto;
    padding: 10px;
    display: flex;
    width: 80%;
    height: 40vw;

    flex-direction: column;

}

#practiceCardBody {
    text-align: left;
    overflow: auto;
    border: 1px solid #1AA99F;
    border-radius: 30px;
    padding: 15px;

}

#practiceDetailsDiv {
    height: 120vh;
    overflow: scroll;
}

#practiceColHeader {

    font-size: 19px;
    width: 80%;
    color: #1AA99F;
    margin: auto;

}

#exploreCard {
    margin: 20px auto;
    text-decoration: none;
    color: inherit;
    width: 90%;
}

#exploreCard:hover {
    cursor: pointer;
    box-shadow: 10px 15px 1px #1AA99F,
        0px 1px 4px #1AA99F;
}


@media screen and (max-width:800px) {
    #practiceContentDiv {
        padding: 0
    }

    .accordion-body {
        padding: 0;
    }

    .hidden-on-small {
        display: none;
    }

    #projectCards {
        width: 100%;
        padding: 0px;

    }

    #practiceColHeader {
        padding-bottom: 0px;
    }

    #practiceWhatRow {
        flex-direction: column-reverse;
    }

    #pAreaContact {
        height: 100px
    }

    #projectTitle {
        word-wrap: break-word;
    }

    #projectAccordion {
        margin: 10px;
        border: 2px solid #1AA99F;
    }

    #practiceCardBody {
        border-radius: 15px;

    }


    #exploreCard {
        margin: 20px;
        text-decoration: none;
        color: inherit;
    }

    #practiceAllDiv {
        width: 100%;
        padding: 0px;
    }

    #practiceBtn {
        margin: 10px;
        width: 100%;

    }

    #practiceCol {

        overflow: auto;
        width: 100%;

    }

    #practiceExploreDiv {
        display: flex;
    }

    #practiceAreaBtnGrp {

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

    }

    #pAreaContact {
        margin-top: 0;
    }


}


#practiceExplore {
    display: flex;
    flex-direction: column;
}




#projectNavLink {
    font-size: 20px;
    text-decoration: none;
    color: inherit;
}

#projectNavLink:hover {
    background-color: #144466;

    color: #1AA99F;

}

#fieldMemberLink {
    text-decoration: none;
    color: inherit;
    margin-top: 9px;
}

#practiceForm {
    margin-top: 10px;
}

#projectLinks {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
}

#projectImage {
    width: 175px;

    margin: auto;
}



#projectCarousel {
    margin-top: 30px;

    height: auto
}