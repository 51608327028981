body {
  margin: 0;
  scrollbar-width: none;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4 {
  color: #1AA99F;
  font-family: 'Playfair Display', serif;
  font-size: 31px;
  font-weight: 600;
  letter-spacing: 2px;

}

p {
  font-family: 'Raleway', sans-serif;
  color: black;
  line-height: 2;
}