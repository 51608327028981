#footerDiv {
    background-color: #041f31;
}

#footerCol {
    display: flex;
    flex-direction: column;
    margin: 20px;

    justify-content: space-evenly;
}

#linkCoverDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:768px) {
    #linkCoverDiv {
        flex-direction: column;
    }

}

#footerLink {
    text-decoration: none;
    color: inherit;
    font-family: 'Raleway', sans-serif;

    text-align: left;
    color: white;

}

#footerCol p {
    color: white;
}



.footerList {
    list-style: none;
}



.footerLink {
    text-decoration: none;
    color: #1AA99F;
}