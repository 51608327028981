#clientsDiv {
    margin-top: 5em;

}

#clientsGrid {
    background-color: white;
    padding: 20px;

}



#clientTitle {
    font-size: 15px;
}



#clientCard {
    margin: 10px;
    width: 45%;
    border: none;
}

#clientInfoImage {

    /*   height: 125px;
    width: 175px;

    max-height: 185px;*/
    max-width: 250px;
    margin: auto;

}

#clientInfoImage:hover {
    cursor: pointer;
}

#allClientRow::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}


.clientButton {

    background-color: inherit;
    color: black;
    font-size: 21px;
    padding: 10px;
    letter-spacing: 1px;
    width: 100%;
    outline: none;
    font-family: 'Playfair Display', serif;
    text-align: left;
    text-transform: capitalize;
    border: none;
}

.clientButton:hover {
    background-color: inherit;
    color: inherit;
}

button:focus:not(:focus-visible) {
    outline-color: #1AA99F;
}

.accordion-button:not(.collapsed) {
    color: white;
    background-color: #1AA99F;
    border: none;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);

}

#clientAccordion {
    width: 80%;
    margin: 20px auto;
    border: 3px solid #1AA99F;
}

#clientAccordion:hover {
    border: 1px solid #1AA99F;
    box-shadow: 10px 15px 1px #1AA99F,
        0px 1px 2px #1AA99F;
    color: inherit;
    background-color: inherit;


}

#clientName {
    font-family: 'Playfair Display', serif;

}

#clientButton:active,
#clientButton:focus {
    border: #1AA99F;
}

#clientButton:hover {
    background-color: #1AA99F;
    font-family: 'Raleway', sans-serif;

}

@media screen and (max-width:768px) {
    #clientCard {
        width: auto;
    }

    #btnDiv {
        display: block;
    }

    .clientButton {
        width: 70%
    }


}