#whoDiv {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: self-end;
}

#coverWho {
    width: 70%;
}

.whoteamLink {
    text-decoration: none;
    color: black;
    justify-content: center;

}


.whoteamLink:hover {
    color: black;

}

#whoBtn {
    background-color: #144466;

}

#whoBtn:hover {
    background-color: #144466;
    box-shadow: 10px 15px 1px #144466,
        0px 4px 2px #1AA99F;
}

#coverTeamCard {
    border: none;
    text-decoration: none;
    color: inherit;
    transition: transform 1s, filter .3s ease-out;
}

#coverTeamCard:hover {
    transform: scale(1.1);
}

#coverTeamPhoto {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

#coverWho {
    padding-top: 2em;

    line-height: 2;
    font-family: 'Raleway', sans-serif;
    color: black;
    width: 100%;
    text-align: left;
}

@media screen and (max-width:768px) {
    #coverWho {
        padding-top: 0px;
    }

}

#coverTeamName {
    color: #1AA99F
}

#coverTeamName,
#coverTeamTitle {
    margin: 10px;
}