#memberBody {
    letter-spacing: 2px;
    word-spacing: 1px;
    font-size: 15px;
}

#memberName {
    margin-top: 40px;
}

#memberBody,
#memberName,
#memberTitle {

    font-family: 'Raleway', sans-serif;
    text-align: center;


}

#memberBody {
    line-height: 30px;
    text-align: left;
    white-space: pre-line;

}


#teamCarousel {
    margin-top: 5vw;

    border: none;
}


.index-hidden {
    display: none;
}

.indicators {

    bottom: 1rem;
}

#teamMemberModal {
    display: flex;

}

.indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: grey;
}

.col-sm-1 {
    margin: auto;
}

.teamArrow {
    color: #1AA99F;

}

.teamArrow:hover {
    cursor: pointer;
}

#teamCard {
    margin: auto;
    border: none;
    width: 100%;
}

#teamModalSection {
    margin: auto
}

#teamCarBody {

    overflow: auto;
    margin: 15px;
}


#allTeamPhoto {
    height: 200px
}

@media screen and (max-width:768px) {
    #teamInfo {
        display: flex;
        flex-direction: column;
    }

    #teamCarBody {
        height: 60vw;
        width: 100%;
        padding: 0;
    }

    #teamModalSection {
        justify-content: center;
        align-items: center;
        display: flex
    }

    #memberName {
        font-size: 20px;
        margin-top: 10px;
    }

    .slick-dots {
        position: relative;
    }

    #memberTitle {
        font-size: 15px;
        margin-top: 10px;
    }

    #allTeamPhoto {
        height: 100px
    }
}