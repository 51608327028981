@media screen and (max-width:768px) {
    .clientArrow {
        display: hidden;
    }
}


.teamContent {
    text-align: center;
    margin-bottom: 30px;
}

.header {
    font-size: 30px;
}



.img-body {
    margin: 10px;
    display: inline-block;
}

#teamCarCard:hover {
    cursor: pointer;

}

#teamCarPhoto:hover {
    cursor: pointer;
}

#teamCardHeader {
    display: flex;
}

#contactImageCard {
    display: flex;
    flex-direction: column-reverse;
}

#teamHeader {
    display: flex;
    flex-direction: column;
}

#teamModal {
    display: flex;
    flex-direction: column;

}

@media screen and (max-width:1000px) {
    #teamHeader {
        display: flex;
        flex-direction: column;
    }

    #imageCol {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;


    }

}