#prDiv {
    margin-bottom: 45px;
    margin-top: 4em;

}

#practiceFactsH {
    color: #1AA99F;
    font-weight: bold;
}

#practiceCoverBtn {
    border: 2px solid #144466;
    color: #1AA99F;
    width: 9em;
    margin: auto;

}


#practiceCoverBtn:hover {
    background-color: #144466;
    box-shadow: 10px 15px 0px #1AA99F,
        1px 4px 2px #1AA99F;
}

#prInfo {
    margin: auto;
    margin-top: 3em;
}


#plxCardTitle>h1 {
    font-size: 45px;
    text-align: center;

}

#prFact {
    font-size: 35px;
    text-align: center;
}

#practiceAreaP {
    font-size: 17px
}





#cTitle {
    text-align: center;
    color: #1AA99F;
    position: absolute;
    top: 50%;
    left: 40%;
    font-size: 30px;
    word-spacing: 5px;

    font-family: 'Playfair Display', serif;

}

@media screen and (max-width: 500px) {
    #cTitle {
        left: 35%;

    }

    .cBody {
        visibility: visible;
        color: black;
        position: absolute;
        visibility: visible;
        top: 30%;
        text-align: center;

        font-family: 'Raleway', sans-serif;
    }

    #prcard {
        margin: 20px;
        width: 100%;
    }
}

.cBody {
    text-align: center;
    visibility: hidden;
    position: absolute;
    margin: 15px;
    top: 15%;
    font-family: 'Raleway', sans-serif;


    transform: translateY(100%);
}

#prRow {
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .card-body {
        padding: 0px
    }

    #plxCardTitle>h1 {
        font-size: 27px;
        text-align: center;
    }

    #plxCoverLeft {
        margin: 0px
    }


    #plxCard:hover {
        cursor: pointer;

    }

    #prFact {
        text-align: center;
        font-size: 30px
    }

    #plxCoverRight {
        display: flex;
        margin: 0px;
        flex-direction: row-reverse;

    }

    #plxCard {

        border-radius: 30px;
        padding: 15px;
        width: 100%;
        margin: 15px auto;
    }
}