#connectDiv {
    margin-top: 5em;
}

#connectRowHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}

#connectionTitle {
    text-align: left;
    margin: 20px
}

#connectQuoteDiv {
    padding: 30px;
    height: auto;
}

.connectQuote {
    font-family: "Tangerine";
    color: #1AA99F;

}

.connectQuoteLang {
    color: white;

}

#connectRow,
#articleRow {
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    border-bottom: 1px solid #1AA99F;


}


#pubRow {
    height: 40vw;
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    border-bottom: 1px solid #1AA99F;
}

#podcastDiv {
    height: 45vw;
    background-color: #1AA99F;
    padding: 30px
}


#newsRowDiv {
    display: flex;

}

#podRow {
    border-bottom: 1px solid #1AA99F;


}

#connectionCard {
    border: 1px solid #041f31;
    font-family: 'Raleway', sans-serif;
    margin: 15px;
    cursor: pointer;
    width: auto;

}


#con #videoCard {
    border: none;
}

#videoDiv {
    border-radius: 20px;
    margin: 10px;
}

#videoTitle,
#videoCat {
    text-align: left;
    text-transform: capitalize;
}

#connectionCard:hover,
#articleCard:hover {
    box-shadow: 10px 15px 1px #1AA99F,
        0px 1px 4px #1AA99F;
}




#podThumbnail {
    height: 100%
}

#podTitle {
    bottom: 25px;
}

#podDate {
    bottom: 8px;
}

#podTitle,
#podDate {
    position: absolute;

    left: 16px;
    text-align: left;
    color: #fff
}

#podRow {

    display: flex;
    flex-direction: row;
}

#podButton {
    border: none;
    margin: 10px;
}

#contentTitle {
    text-transform: capitalize;
    text-align: center;
}

#contentText,
#contentTitle {
    padding-top: 10px;

}

#contentTitle,
#contentSub,
#contentText {
    text-align: left;
}

#connectBtn {
    border: 2px solid #144466;
    color: #1AA99F;
    width: 9em;

    text-transform: uppercase;
}

#connectBtn:hover {
    background-color: #144466;
    box-shadow: 10px 15px 0px #1AA99F,
        1px 4px 2px #1AA99F;
}

#currentNewsDiv {
    text-align: left;
}

#connectInnerDiv {
    background-color: white;
    padding: 20px
}

@media screen and (max-width:768px) {

    #articleRow,
    #connectRow,
    #pubRow,
    #connectRowHeading {
        flex-direction: column;
        align-items: center;
    }

    #connectionCard {
        padding: 10px;

    }

    #podcastDiv {
        height: auto;
    }

}