#allVideosDiv {
    margin-top: 10vw;
}

#videoTitle,
#videoCat,
#videoDesc {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 1px;
    padding: 10px;
}

#tabsLink {
    text-decoration: none;
    color: inherit;
}

#allVideosThumb {
    width: 350px;
    height: 425px;
    margin: 10px;
}

#allVideoRow {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:768px) {
    #allVideosThumb {
        width: 100%;
    }

    #allVideoRow {
        flex-direction: column;
    }
}

#tabItem:hover {
    background-color: #1AA99F,

}