#subBtn {
    color: #1AA99F;
    margin-top: 20px;
    border: 1px solid #1AA99F;
}

#startBtn {
    color: #1AA99F;

    border: 1px solid #1AA99F;
}

#locationMap {
    width: 500px;
    height: 450px;
    border: 0
}

#careersCoverRow {
    margin: 20px
}

#contactDiv {
    display: block;
    margin-top: 5em;


}

#contactInnerDiv {
    background-color: white;
    padding: 20px;
}

.form-group {
    margin-bottom: 20px
}

@media screen and (max-width:750px) {
    #locationMap {
        width: 400px;
        height: 200px;
        margin-top: 20px;

        margin: 0;
    }


}

#subBtn:hover,
#startBtn:hover {
    background-color: #144466
}

#contactText {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 2px;

}

#contactDiv {
    display: block;

}

#careersCardTitle {
    font-family: 'Playfair Display', serif;
    text-align: left;
    font-size: 27px;
    letter-spacing: 1px;
    word-spacing: 5px;
    padding: 20px;
}

#careersCardText {
    font-family: 'Raleway', sans-serif;
    text-align: left;
    letter-spacing: 1px;
    word-spacing: 5px;
    padding: 20px;
    text-transform: capitalize;


}

#contactBtn {
    border: 2px solid #1AA99F;
    color: #1AA99F;
    width: 9em;
    float: right;
    text-transform: uppercase;
}

#contactBtn:hover {
    background-color: white;
    box-shadow: 10px 15px 0px #1AA99F,
        1px 4px 2px #1AA99F;
}

#careersCoverLeft,
#careersCoverRight {
    transition: transform .8s, filter .3s ease-out;
    margin-top: 30px;
    margin-bottom: 30px;


}

#careersCard {
    margin: 20px;
    border: none;
    text-decoration: none;
    color: inherit;
    display: flex;
    border: none;
    background-color: inherit;

}