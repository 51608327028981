#contentBtn {
    width: 50%;
    margin: auto;
}


#coverConnectCard {
    padding: 15px;

    margin: auto
}

#connectCard {
    margin: 20px;
    width: 33%;
    padding: 20px;
    border-radius: 15%;
    text-decoration: none;
    color: inherit;
}


#serviceP {
    text-align: left;
}

#cItem {
    padding: 0;

}

#carImage {
    image-rendering: auto;
}

#connectTitle {
    text-align: left;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    border-radius: 40%;
    margin-top: 15px;

}

#landingBtn {
    margin-top: 40px;

    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
    border: 2px solid #1AA99F;
    background-color: inherit;
    height: 3em;
    width: 13em;
    float: left;

    color: white;
    text-transform: uppercase;
}

#landingText {
    text-transform: capitalize;
}

.landLink {
    text-decoration: none;
    color: #1AA99F;
    text-transform: capitalize;
    font-size: 20px;

}

#landingBtn:hover {

    box-shadow: 0 1px 4px #1AA99F,
        0 1px 4px #1AA99F;
}



#divInfo {
    width: 100%;
    padding-top: 25%;
    height: 100%;

    justify-content: center;
}

#carCaption {
    background-color: #144466;
    top: 100;
    bottom: auto;
}


.landingTitle {
    letter-spacing: 3px;
}

.contentRow {

    padding-top: 3em;
}

#coverDiv {
    margin: auto;
    image-rendering: auto;

}

#contentHub {

    width: 100%;


}

#plxCardTitle {
    font-family: 'Playfair Display', serif;
    text-align: left;
    font-size: 27px;
    letter-spacing: 1px;
    word-spacing: 5px;
}

#plxCardText,
#plxCardSub {
    font-family: 'Raleway', sans-serif;

    letter-spacing: 1px;
    word-spacing: 5px;
    text-transform: capitalize;


}

#plxCoverLeft,
#plxCoverRight {
    transition: transform .8s, filter .3s ease-out;
    margin-top: 30px;



}

#plxCard {
    margin: 20px;
    border: none;
    text-decoration: none;
    color: inherit;
    display: flex;
    border: none;

}



#carImage {
    width: 100%;
    height: 100vh;
    background-size: cover;
}

#plxCoverLeft:hover,
#plxCoverRight:hover {
    border-radius: 30px;
    box-shadow: 0 1px 4px #1AA99F,
        0 1px 4px #1AA99F;
    cursor: pointer;
    transform: scale(1.1);
}



#landingCover {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    border: 0;
    padding: 0;
    background-color: #041f31;
}

#landingText {
    font-weight: 700;
    letter-spacing: 2px;
    word-spacing: 10px;
    padding-left: 15px;
    font-size: 45px;
    text-align: left;
    color: white;
}

#locationMap {
    width: 100%;
}

#carText {
    margin-top: 60%;
}



#clientsCoverDiv {
    padding: 20px;
}


#connectHub {
    height: auto;
    display: flex;
    flex-direction: row;
}


.contentCards {
    height: 17em;
    width: 33%;
    margin: auto;
    justify-content: space-evenly;
    border: 2px solid #1AA99F;
    box-shadow: 1em;
    border-radius: 20px;
}

#fieldSumamry {
    margin: 20px;
}

.contentCards:hover {
    background-color: #144466;
    color: #1AA99F;
    box-shadow: 0 1px 4px #1AA99F,
        0 1px 4px #1AA99F;
}

.contentLink {
    text-decoration: none;
    justify-content: center;
    padding-top: 10px;
    justify-content: right;
    color: #1AA99F
}


.contentLink:hover {
    color: white;
}

.cardTitle {
    padding-bottom: 20px;
}

.cardP {
    margin: auto;
    padding: 10px;
}

#carCoverLink {
    text-decoration: none;
    color: inherit;
}

#contactCoverRow {
    margin-left: 30px;
    margin-top: 3em;

}

@media screen and (max-width:800px) {


    #cItem {
        height: 145vw
    }

    #landingText {
        padding-left: 0px;
        font-size: 35px
    }

    #landingCover {
        height: 140vw;
    }

    #connectCard {
        width: 100%;
    }

    #connectHub {
        height: auto;
        margin: auto;
        flex-direction: column;

    }

    .carousel-indicators {
        position: absolute;
        bottom: -15vw;
    }

    #fieldSummary {
        margin: 0px;
    }

    #carText {
        margin-top: 4em;
        padding: 30px
    }

    #locationMap {
        margin: auto;
        width: 80%;
    }

    #landingText,
    #landingBtn {
        margin-top: 20px
    }

    #clientsCoverDiv {
        display: block;
    }

    #contactCoverRow {
        margin-left: 0;
    }
}