#articleDiv {
    margin-top: 6em;
}

#articleCard {
    margin: 20px auto;
    justify-content: space-between;
    padding: 10px;
    width: 80%;

}




#articleTitle {
    font-size: 45px;
    text-transform: capitalize;
}

#articleTitle,
#articleDate,
#articleText {

    font-family: 'Raleway', sans-serif;
    text-align: left;
    text-decoration: none;
}

#currentArticleDiv {
    padding: 30px;
    margin: 20px;

}

#articleCardTitle,
#articleSub,
#articleText {
    text-align: left;
}